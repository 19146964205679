<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <sticky-header :title="$route.meta.title"></sticky-header>
    <div class="flex-fill p-3">
      <b-row class="mb-2">
        <b-col cols="12" md="9">
          <b-form-input debounce="500" @update="load" placeholder="Search for statement..." type="text" v-model="query" />
        </b-col>
        <b-col cols="12" md="3">
          <b-button block @click="filtersShown = !filtersShown" variant="dark">Filters <fa-icon class="ml-2" icon="chevron-down" v-if="!filtersShown" /><fa-icon class="ml-2" icon="chevron-up" v-else /></b-button>
        </b-col>
      </b-row>
      <b-card v-if="filtersShown">
        <b-row>
          <b-col cols="12" md="2">
            <b-form-group>
              <label class="mb-0" for="startPeriod">Start of Period</label>
              <b-form-select id="startPeriod" v-model="selectedStartPeriod" :options="filterPeriods" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2">
            <b-form-group>
              <label class="mb-0" for="endPeriod">End of Period</label>
              <b-form-select  id="endPeriod" v-model="selectedEndPeriod" :options="filterPeriods" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <b-card class="text-center mt-2 py-5" v-if="!loading && data.length === 0">
        <p class="h3 font-weight-bold">No statements found.</p>
        <p class="text-muted mb-0">You may not see details of financials on bookings until they start.</p>
      </b-card>
      <table class="table" v-if="!loading">
        <thead>
          <tr>
            <th scope="col">Period</th>
            <th scope="col">Statement Number</th>
            <th scope="col">Property</th>
            <th scope="col">Total amount due</th>
            <th scope="col">Mgmt fee</th>
            <th scope="col">Mgmt VAT</th>
            <th scope="col">Due to LL</th>
            <th scope="col">Cleaning costs</th>
            <th scope="col">Cleaning VAT</th>
            <th scope="col">Cleaning total</th>
            <th scope="col">Extra deductions</th>
            <th scope="col">Amount Paid to LL</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody class="mt-2 mb-4">
          <tr :key="statement.id" v-for="statement in data">
            <td>{{ moment(statement.issued_at).format('MMM-YY') }}</td>
            <td>{{ statement.statement_no }}</td>
            <td>{{ statement.property.name }}</td>
            <td>{{ concatPoundMinus(statement.amount) }}</td>
            <td>{{ concatPoundMinus(statement.amount_mgmt) }}</td>
            <td>{{ concatPoundMinus(statement.amount_tax) }}</td>
            <td>{{ concatPoundMinus(statement.amount_client) }}</td>
            <td>{{ concatPoundMinus(-statement.amount_deduct) }}</td>
            <td>{{ concatPoundMinus(-statement.amount_deduct_tax) }}</td>
            <td>{{ concatPoundMinus(-statement.amount_deduct_total) }}</td>
            <td>{{ concatPoundMinus(-statement.amount_deduct_extra) }}</td>
            <td>{{ concatPoundMinus(statement.amount_client + statement.amount_deduct_total + statement.amount_deduct_extra) }}</td>

            <div>
              <b-button :variant="statement.approved ? 'success' : 'danger'" class="mr-2 mt-1" v-b-popover.top.hover="statement.approved ? 'Unapprove Statement' : 'Approve Statement'" @click="onClickToggle(statement)">
                <fa-icon icon="check" v-if="statement.approved"/>
                <fa-icon icon="plus" style="transform: rotate(45deg);" v-if="!statement.approved"/>
              </b-button>
              <b-button class="mt-1" :href="`/api/statements/${statement.id}/pdf?download=false`" target="_blank" variant="light" v-b-popover.top.hover="`View Statement`">
                <fa-icon class="mr-2" icon="file-invoice-dollar"/>
                View
              </b-button>
            </div>
          </tr>
        </tbody>
      </table>
      <div class="d-flex">
        <b-pagination-nav class="flex-fill" :link-gen="paginationLinkGen" :number-of-pages="lastPage" use-router v-if="lastPage > 1" />
        <div>
          <b-button :href="onClickExport()" variant="outline-primary">Export as CSV</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: { StickyHeader },
  data() {
    const periods = Array.from(Array(72).keys()).map(m => moment().subtract(m, 'months'))

    return {
      query: '',
      selectedStartPeriod: moment().format('YYYY-MM-[01]'),
      selectedEndPeriod: moment().format('YYYY-MM-[01]'),
      filtersShown: true,
      filterPeriods: periods.map(p => ({ text: p.format('MMM YYYY'), value: p.format('YYYY-MM-[01]') }))
    };
  },
  async mounted() {
    await this.load();
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('statements', ['data', 'loading', 'lastPage'])
  },
  methods: {
    ...mapActions('statements', ['fetch', 'exportAsCSV', 'toggleApprove']),
    moment,
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: 'monthly-accounts', query: pickBy({...this.$route.params, page: null}, identity)}
        : {name: 'monthly-accounts', query: {...this.$route.params, page: pageNo}};
    },
    pluralise(count, term) {
      if (count === 1) return term;
      return `${term}s`;
    },
    async onClickToggle(statement) {
      await this.toggleApprove(statement.id);
      await this.load();
    },
    concatPoundMinus(num) {
      if (num < 0) {
        return  '-£' + Math.abs(num).toFixed(2);
      } else {
        return '£' + num.toFixed(2);
      }
    },
    async load() {
      await this.fetch(Object.fromEntries(Object.entries({
        query: this.query,
        page: this.$route.query.page,
        start_month: this.selectedStartPeriod ? this.selectedStartPeriod: undefined,
        end_month: this.selectedEndPeriod ? this.selectedEndPeriod : undefined
      }).filter(([,v]) => v !== undefined)));
    },
    onClickExport() {
      return `/api/statements/export.csv?query=${this.query}&start_month=${this.moment(this.selectedStartPeriod ? this.selectedStartPeriod: undefined).startOf('month').format('YYYY-MM-DD')}&end_month=${this.moment(this.selectedEndPeriod ? this.selectedEndPeriod: undefined).startOf('month').format('YYYY-MM-DD')}`;
    }
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      if (newValue.name === value.name && newValue.query.page !== value.query.page) {
        await this.load();
      }

      return this.$bvModal.hide(value.meta.modalId);
    },
    async selectedStartPeriod() { await this.load() },
    async selectedEndPeriod() { await this.load() },
  }
}
</script>

<style>

</style>
